import React, { FC, SyntheticEvent } from 'react';
import { Modal, Button } from '@tidbits/react-tidbits';

interface Props {
  selectedKey: string;
  hide: (event: SyntheticEvent) => void;
  setRef: (refObject: any) => void;
}

export const UserPreferenceFooter: FC<Props> = ({ selectedKey, setRef, hide }) => {
  const isHelpRelated = () => {
    switch (selectedKey) {
      case 'hotKeys':
      case 'queriesAndEscalations':
      case 'myProfile':
        return (
          <Modal.Footer display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              ref={setRef}
              primary
              large
              standard
              type="button"
              disabled={false}
              onClick={(e: any) => {
                hide(e);
              }}
              mr={10}
            >
              Close
            </Button>
          </Modal.Footer>
        );
      case 'betaFeatures':
      case 'settings':
        return (
          <Modal.Footer display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              ref={setRef}
              large
              standard
              type="button"
              disabled={false}
              onClick={(e: any) => {
                hide(e);
              }}
              mr={10}
            >
              Cancel
            </Button>
            <Button
              ref={setRef}
              primary
              large
              standard
              type="button"
              disabled={false}
              onClick={(e: any) => {
                hide(e);
                window.location.reload();
              }}
              mr={10}
            >
              Apply
            </Button>
          </Modal.Footer>
        );
      case 'globalAlerts':
        return (
          <Modal.Footer display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              ref={setRef}
              large
              standard
              type="button"
              disabled={false}
              onClick={hide}
              mr={10}
            >
              Cancel
            </Button>
          </Modal.Footer>
        );
      default:
        return (
          <Modal.Footer display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              ref={setRef}
              large
              standard
              type="button"
              disabled={false}
              onClick={hide}
              mr={10}
            >
              Cancel
            </Button>
            <Button
              ref={setRef}
              large
              standard
              type="button"
              disabled={false}
              onClick={hide}
              mr={10}
            >
              Update
            </Button>
          </Modal.Footer>
        );
    }
  };

  return <>{isHelpRelated()}</>;
};
