import { WorkflowType } from '@helper-hooks/user-preferences-redux-action';
import { PERMISSIONS } from '../../../../common';

export const getWorkflowTypeUserPreferenceKey = (workflowType: string) => {
  switch (workflowType) {
    case WorkflowType.MUSIC:
      return 'mcw';
    case WorkflowType.VIDEO:
      return 'vcw';
    case WorkflowType.WORKLIST:
      return 'worklist';
    case WorkflowType.COPY:
      return 'cw';
    case WorkflowType.TASK:
      return 'task';
    default:
      return '';
  }
};

export const getTypeTitle = (workflowType: WorkflowType) => {
  switch (workflowType) {
    case WorkflowType.MUSIC:
      return 'Music Workflow';
    case WorkflowType.VIDEO:
      return 'Video Workflow';
    case WorkflowType.WORKLIST:
      return 'Worklist';
    case WorkflowType.COPY:
      return 'Copy Workflow';
    case WorkflowType.TASK:
      return 'Task';
    default:
      return '';
  }
};

export const getAccordionTitle = (permission: string) => {
  switch (permission) {
    case PERMISSIONS.acwCAnAccessNAmespace:
      return 'Apps Canvas Workflow';
    case PERMISSIONS.mcwCanAccessNamespace:
      return 'Music Workflow';
    case PERMISSIONS.vcwCanAccessNamespace:
      return 'Video Workflow';
    case PERMISSIONS.cwCanAccessNamespace:
      return 'Copy Workflow';
    default:
      return '';
  }
};
