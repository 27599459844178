import React, { FC } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Modal } from '@tidbits/react-tidbits';

import '../../style/modal.scss';

// eslint-disable-next-line import/no-cycle
import { hideModal } from '../../store/modal/modalActions';
import Modals from '../../components/modals';
import { ModalProps } from '../types';

export const MODAL_CONSTANTS = {
  exportValid: 'exportValid',
  simpleInfo: 'simpleInfo'
};

export const MODAL_CONTENTS = {
  [MODAL_CONSTANTS.exportValid]: Modals.ExportValidModal,
  [MODAL_CONSTANTS.simpleInfo]: Modals.SimpleInfoModal
};

interface Props {
  modalType?: string;
  modalProps?: ModalProps;
  closeModal: () => void;
}

interface StateProps {
  modalType: string;
  modalProps: ModalProps;
}

interface DispatchProps {
  closeModal: () => void;
}

type ComponentProps = Props & StateProps & DispatchProps;

const ModalContainer: FC<ComponentProps> = ({ modalType, modalProps, closeModal }) => {
  const { size, open } = modalProps;

  if (!modalType) {
    return null;
  }

  const ContentModal = MODAL_CONTENTS[modalType];

  const classes = classNames('mpe-modal-container_modal', `mpe-modal-size-${size}`, {
    'mpe-modal-container-modal-fixed-height': modalType === 'reassignUser'
  });

  return (
    <Modal.Window
      id="modal"
      open={open}
      closeModal={closeModal}
      zIndex={20}
      closeOnEscape
      closeOnClickOutside
    >
      <div className={classes}>
        <ContentModal {...modalProps} />
      </div>
    </Modal.Window>
  );
};

interface ReduxState {
  modal: {
    modalType: string;
    modalProps: ModalProps;
  };
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  modalType: state.modal.modalType,
  modalProps: state.modal.modalProps
});

const mapDispatchToProps: DispatchProps = {
  closeModal: hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);

/* Modal is handled via Redux store. There are two events: show and hide modal.
 Modal is opening on changed isOpen prop in reducer. Whit this logic we have one modal dialog,
 which is included in Layout and handled via store. Any modal can receive custom props via reducer.
 more about implementation can be found: https://itnext.io/integrating-semantic-ui-modal-with-redux-4df36abb755c
 */
