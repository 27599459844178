import { QualifiedUser } from '@providers/types-provider';
import { ReassignTaskActionTypes, TaskReassignActionTypes } from './reassignTypes';

const initialState = [] as QualifiedUser[];

export default function reassignReducer(state = initialState, action: ReassignTaskActionTypes) {
  switch (action.type) {
    case TaskReassignActionTypes.GET_REASSIGN_LIST_SUCCESS:
      return [...action.payload];
    case TaskReassignActionTypes.CLEAR_REASSIGN_LIST:
      return [];
    default:
      return state;
  }
}
