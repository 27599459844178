import { FormType, WorkflowSettings } from '../../../../common/types/UserPreferences';

import { WorkflowType } from '@helper-hooks/user-preferences-redux-action';

export const SettingsConfig: WorkflowSettings[] = [
  {
    workflowType: WorkflowType.WORKLIST,
    features: [
      {
        propertyKey: 'pageSize',
        label: 'Select the page size for the worklist query',
        defaultValue: 50,
        type: FormType.DROPDOWN_SELECTION,
        options: [
          {
            value: 25,
            label: '25'
          },
          {
            value: 50,
            label: '50'
          },
          {
            value: 75,
            label: '75'
          },
          {
            value: 100,
            label: '100'
          }
        ]
      }
    ]
  },
  {
    workflowType: WorkflowType.TASK,
    features: [
      {
        propertyKey: 'sourceLocatedLeft',
        label:
          'Source copy location - Select where to place the source copy and its corresponding target copy on a shelf',
        defaultValue: false,
        type: FormType.RADIO,
        options: [
          {
            value: false,
            label: 'Place the source locale to the right and the target locale to the left'
          },
          {
            value: true,
            label: 'Place the source locale to the left and the target locale to the right'
          }
        ]
      }
    ]
  }
];
