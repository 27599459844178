import { WorkflowUser } from '@providers/types-provider';

import { User } from '../../common/types';
import { mapSimpleUsersFromApiToClient } from './mappers/SimpleUserMapper';
import ApiSimpleUser from './models/ApiSimpleUser';
import ApiItemsCollection from './interfaces/ApiItemsCollection';
import ClientItemsCollection from './interfaces/ClientItemsCollection';
import { mapApiItemsCollectionToClientItemsCollection } from './mappers/ItemsCollectionMapper';

export const addMissingUserFields = (user: WorkflowUser): WorkflowUser => {
  const { id, name, email, permissions, username, userRealm, fullyQualifiedId } = user;

  return {
    id,
    name,
    email,
    permissions,
    username,
    userRealm,
    fullyQualifiedId,
    // TODO: Remove these fields (this was taken from old architecture, investigation is needed to check what they are used for)
    accessToken: 'UNKNOWN_ACCESS_TOKEN',
    jobTypes: []
  };
};

export const adoptSimpleUsers = (
  apiUserCollection: ApiItemsCollection<ApiSimpleUser>,
  ss?: boolean
): ClientItemsCollection<User> => {
  const mapFunction = mapSimpleUsersFromApiToClient;
  return mapApiItemsCollectionToClientItemsCollection(apiUserCollection, mapFunction);
};

export default addMissingUserFields;
