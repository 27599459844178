import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import InlineSpinner from '@ui-components/inline-spinner';

import { isUserAuthenticated, getUserPermissions } from '../store/user/userSelector';
import { ROUTES } from '../common';
import { updateMatch } from '../store/match/matchAction';
import { isAllowedByPermissions } from '../utils/generalUtils';

class UserRoute extends React.Component {
  componentDidMount() {
    const { computedMatch, changeMatch } = this.props;
    changeMatch(computedMatch);
  }

  isAllowedByPermissions = (isAuthenticated, allowedPermissions, userPermissions) =>
    isAuthenticated && isAllowedByPermissions(allowedPermissions, userPermissions);

  render() {
    const {
      isAuthenticated,
      userPermissions,
      allowedPermissions,
      component: Component,
      ...rest
    } = this.props;
    const isUserAllowedByPermissions = this.isAllowedByPermissions(
      isAuthenticated,
      allowedPermissions,
      userPermissions
    );
    return (
      <Route
        {...rest}
        render={(props) =>
          isUserAllowedByPermissions ? (
            <Suspense fallback={<InlineSpinner visible={true} size={30} spinnerSpeed={500} />}>
              <Component {...props} />
            </Suspense>
          ) : (
            <Redirect to={ROUTES.welcomePage} />
          )
        }
      />
    );
  }
}

UserRoute.propTypes = {
  component: PropTypes.shape().isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeMatch: PropTypes.func.isRequired,
  computedMatch: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: isUserAuthenticated(state),
  userPermissions: getUserPermissions(state)
});

const mapDispatchToProps = {
  changeMatch: updateMatch
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRoute);
