import { all } from 'redux-saga/effects';
import availableTermsSaga from './availableTerms/availableTermsSaga';
import taskSaga from './task/taskSaga';
import userSaga from './user/userSaga';
import UserPreferencesRedux from '@helper-hooks/user-preferences-redux-action';
import worklistSaga from './worklist/worklistSaga';
import alertSaga from './alerts/alertsSaga';
import api from '../api/api';
import reassignSaga from './reassignment/reassignSaga';

export default function* rootSaga() {
  yield all([
    taskSaga(),
    userSaga(),
    worklistSaga(),
    availableTermsSaga(),
    UserPreferencesRedux.userPreferencesSaga(
      api.userPreferences.getPreferences,
      api.userPreferences.putPreferences
    ),
    alertSaga(),
    reassignSaga()
  ]);
}
