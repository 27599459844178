import {
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  FETCH_USER_SUCCESS,
  SET_TABLE_HEADERS_SUCCESS,
  SET_USER_AVAILABILITY_SUCCESS,
  INIT_USER_PREFERENCES
} from './userActionTypes';

const initialState = {};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return { ...state, ...action.user };
    case USER_LOGOUT_SUCCESS:
      return {};
    case FETCH_USER_SUCCESS:
      return { ...state, ...action.user };
    case SET_TABLE_HEADERS_SUCCESS: {
      let userPreferences;
      if (state.userPreferences && state.userPreferences[action.userPreferences.collection]) {
        userPreferences = state.userPreferences[action.userPreferences.collection];
      } else {
        userPreferences = {};
      }

      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          [action.userPreferences.collection]: {
            ...userPreferences,
            columnPreference: {
              ...userPreferences.columnPreference,
              ...action.userPreferences.columnPreference
            }
          }
        }
      };
    }
    case INIT_USER_PREFERENCES:
      return {
        ...state,
        userPreferences: action.userPreferences
      };
    case SET_USER_AVAILABILITY_SUCCESS:
      return { ...state, available: action.user.available };
    default:
      return state;
  }
}
