import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import NavigationBarLayout from '@ui-components/navigation-bar-layout';
import IconTemplate from '@ui-components/icon-template';

import NavigationBarMenuRight from './components/NavigationBarRight/NavigationBarMenuRight';
import { ROUTES } from '../../common/index';
import getMatch from '../../store/match/matchSelector';

import { getTaskWorkflowType } from '../../store/task/taskSelector';
import { ReduxState } from '../../common/types';
import history from '../../utils/history';
import HeaderNavigationLeft from '../HeaderNavigationLeft';
import MediaTypeIcon from './components/MediaTypeIcon/MediaTypeIcon';

import { ModalProvider, ModalRoot } from '@providers/modal-provider';

type Props = RouteComponentProps;

const NavigationBar: FC<Props> = () => {
  const user = useSelector((state: ReduxState) => state.user);
  const matchFromStore = useSelector(getMatch);
  const workflowType = useSelector(getTaskWorkflowType);

  const handleHeaderLogoClick = () => {
    if (matchFromStore) {
      const { path } = matchFromStore;

      switch (path) {
        case ROUTES.moduleTaskDetails:
          history.push(ROUTES.worklist);
          break;
        default:
          history.push(ROUTES.worklist);
      }
    }
  };

  return (
    <ModalProvider>
      <NavigationBarLayout
        banner={
          <HeaderNavigationLeft
            mediaIcon={<MediaTypeIcon workflowType={workflowType} />}
            logoIcon={
              <IconTemplate
                name="headerNavBarIcon"
                onClick={handleHeaderLogoClick}
                width={50}
                height={50}
                pointer
              />
            }
          >
            <ModalRoot />
          </HeaderNavigationLeft>
        }
        user={
          <NavigationBarMenuRight
            isSearchVisible={false}
            userDisplayName={user.name}
            userJobTypes={user.jobTypes.join(',')}
          />
        }
      />
    </ModalProvider>
  );
};

export default withRouter(NavigationBar);
