import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CLEAR_USER_DATA,
  INIT_USER_PREFERENCES
} from './userActionTypes';

export const userLoginRequest = (jwt) => ({
  type: USER_LOGIN_REQUEST,
  jwt
});

export const userLoginSuccess = (user) => ({
  type: USER_LOGIN_SUCCESS,
  user
});

export const userLoginFailure = (error) => ({
  type: USER_LOGIN_FAILURE,
  error
});

export const clearUserData = () => ({ type: CLEAR_USER_DATA });

export const userLogoutRequest = () => ({
  type: USER_LOGOUT_REQUEST
});

export const userLoggedOut = () => ({
  type: USER_LOGOUT_SUCCESS
});

export const fetchCurrentUserRequest = () => ({
  type: FETCH_USER_REQUEST
});

export const fetchCurrentUserSuccess = (user) => ({
  type: FETCH_USER_SUCCESS,
  user
});

export const fetchCurrentUserFailure = (error) => ({
  type: FETCH_USER_FAILURE,
  error
});

export const initUserPreferences = (userPreferences) => ({
  type: INIT_USER_PREFERENCES,
  userPreferences
});
