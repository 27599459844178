import { createSelector } from 'reselect';
import { ReduxState } from '../../common/types';

export const userSelector = (state: ReduxState) => state.user;
export const isUserAuthenticated = (state: ReduxState) => !!state.user.accessToken;

export const getUserPermissions = createSelector(userSelector, (user) =>
  user.permissions ? user.permissions : []
);
export const getUsersPreferences = createSelector(userSelector, (user) => {});

const getUserColumns = createSelector(userSelector, (user) => []);
export const getTaskHeadersSelector = createSelector(getUserColumns, (userColumns) => []);
