import React from 'react';
import { useSelector } from 'react-redux';
import { Panel, Text, Box, HR } from '@tidbits/react-tidbits';
import { FormInputs } from '../Input/FormInputs';
import { getTypeTitle } from '../../utils/utils';
import { SettingsType } from '@helper-hooks/user-preferences-redux-action';
import { FeatureSetting } from '../../../../../common/types/UserPreferences';
import { getSettings } from '../../../../../store/userPreferences/userPreferencesSelector';
import { SettingsConfig } from '../../config/SettingsConfig';

export const Settings = () => {
  const currentSettings = useSelector(getSettings);

  const renderFormInputs = (workflowType: string, feature: FeatureSetting) => {
    const { propertyKey } = feature;
    const workflowBetaFeatures = currentSettings[workflowType as keyof typeof currentSettings];
    const currentValue = workflowBetaFeatures[propertyKey as keyof typeof workflowBetaFeatures];

    return (
      <FormInputs
        value={currentValue}
        settingsType={SettingsType.SETTINGS}
        workflowType={workflowType}
        {...feature}
      />
    );
  };

  return (
    <Panel height={'100%'}>
      <Text sb="spacer15" textStyle="h3Emph">
        Settings
      </Text>
      <HR />
      <Box>
        {SettingsConfig.map(({ workflowType, features }) => {
          return (
            <Box pb={15} pt={15}>
              <Text textStyle="h5Medium" sb="spacer15">
                {getTypeTitle(workflowType)}
              </Text>
              {features.map((feature: any) => {
                return <>{renderFormInputs(workflowType, feature)}</>;
              })}
              <HR />
            </Box>
          );
        })}
      </Box>
    </Panel>
  );
};
