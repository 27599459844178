import React, { FC } from 'react';
import UserAvatar from '@ui-components/user-avatar';
import IconTemplate from '@ui-components/icon-template';
import { Box } from '@tidbits/react-tidbits';

import { getInitials } from '../../../../utils/generalUtils';

const NavigationBarProfile: FC<any> = ({ userDisplayName, userJobTypes }) => {
  return (
    <>
      <UserAvatar displayName={getInitials(userDisplayName)} />
      <Box className="mpe-navigation__user-info">
        <Box>
          <span className="mpe-navigation__user-last-name">{userDisplayName}</span>
          <span id="userRole" className="mpe-navigation__login-dropdown-text-color-opacity">
            {` ${userJobTypes}`}
          </span>
        </Box>
      </Box>
      <Box>
        <IconTemplate
          name="expand"
          width={10}
          height={10}
          color="blue"
          className="mpe-dropdown-arrow mpe-dropdown-arrow--nav-bar"
        />
      </Box>
    </>
  );
};

export default NavigationBarProfile;
