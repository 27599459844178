import React from 'react';

export const LoginPage = React.lazy(() => import('./LoginPage'));

export const ModuleTaskDetailsPage = React.lazy(() => import('./ModuleTaskDetailsPage'));

export const WelcomePage = React.lazy(() => import('./WelcomePage'));

export const ReleaseDetailsPage = React.lazy(() => import('./ReleaseDetailsPage'));

export const WorklistPage = React.lazy(() => import('./WorklistPage'));

export const CopyManagementPage = React.lazy(() => import('./CopyManagementPage'));
