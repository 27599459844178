import moment from 'moment';
import CryptoJS from 'crypto-js';
import { intersection } from 'lodash';

import { showToast } from '../store/toast/toastActions';
import { SearchRequest } from './RSQL/SearchRequest';

import {
  TASK_STATES,
  JOB_TYPES,
  REGISTERED_SHELVES,
  CONTENT_TYPES,
  WORKFLOW_NAMESPACES
} from '../common';

export const capitalizeFirstLetter = (string) => {
  const lowerCaseString = string.toLowerCase();
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
};

export const getDefaultParams = (queryParams) => {
  const { nextCursor, limit, sort, filter, searchTerm } = queryParams;
  const apiLimit = limit || 0;
  const apiFilter = filter ? [...filter] : [];
  return {
    nextCursor,
    limit: apiLimit,
    sort,
    filter: apiFilter,
    searchTerm
  };
};

// TODO: Easy fix for fetching data because of api bug
export const getDefaultQParam = (queryParams) => {
  const { q } = queryParams;
  if (!q) {
    return { ...queryParams, q: 'id==*' };
  }

  return queryParams;
};

// TODO getDefaultParams will probably be switched with this method in the future, so let's keep them both for now
export const getDefaultQueryParams = (getParams) => {
  const { nextCursor, limit, sort, filter, searchTerm } = getDefaultParams(getParams);
  if (searchTerm) {
    const searchParam = `*${searchTerm.toLowerCase()}*`;
    filter.push({ field: 'text', operation: '', value: [searchParam] });
  }

  const filterItems = filter.map((filterItem) => {
    if (filterItem.field === 'assignee') {
      const flattenValue = filterItem.value.map((item) => item.value);
      return {
        field: 'assigneeId',
        operation: filterItem.operation,
        value: flattenValue
      };
    }
    return filterItem;
  });

  const searchRequest = SearchRequest.builder()
    .withFilter(filterItems)
    .withLimit(limit)
    .withSort(sort)
    .withNextCursor(nextCursor)
    .build();

  return searchRequest.toSuperScalarRequest();
};

export const addQueryParamsToRoute = (route, params) => {
  const { q, sort, pageSize, cursor } = params;
  const pageSizeParam = pageSize ? `&pageSize=${pageSize}` : '';
  const sortParam = sort ? `&sort=${encodeURIComponent(sort)}` : '';
  const cursorParam = cursor ? `&cursor=${encodeURIComponent(cursor)}` : '';
  const qParam = q ? `q=${q}` : 'q=id==*';
  const queryPrefix = pageSizeParam || sortParam || cursorParam || qParam ? '?' : '';

  return `${route}${queryPrefix}${qParam}${cursorParam}${pageSizeParam}${sortParam}`;
};

export const addIdParamsToRoute = (route, ids) =>
  `${route}?${ids.map((id) => `id=${id}`).join('&')}`;

// toast
export const generateToastMessage = (toastType, translateKey, translateData = null) =>
  showToast(true, {
    toastType,
    toastMessage: {
      translateKey,
      translateData
    }
  });

// dateTime
export const modifyDateToExtendedNotation = (dateString) =>
  dateString.replace(/([+-]\d\d)(\d\d)$/, '$1:$2');

export const isDifferenceBetweenDatesGreaterThanMinutes = (from, to, minutes) => {
  const dateFrom = Date.parse(from);
  const dateTo = Date.parse(to);
  const diffInMinutes = (dateTo - dateFrom) / (1000 * 60);
  return diffInMinutes > minutes;
};

export const remainingDays = (to) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const dateTo = new Date(to);
  const dateFrom = Date.now();

  return Math.floor((dateTo.getTime() - dateFrom) / oneDay);
};

export const getDateTimestampString = (date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();

// when feeding Calendar date info, we strip it from timezone extensions and leave a bare date
// e.g 2020-07-08T00:00:00.000Z => 2020-07-08
// this is done so Calendar keeps the date as it is and not try to convert it to local timezone,
// which may result in date change in some cases
export const getDateFrom = (dateRange) => (dateRange.length ? dateRange[0] : '').substring(0, 10);

export const getDateTo = (dateRange) => (dateRange.length > 1 ? dateRange[1] : '').substring(0, 10);

// task export

export const parseTaskExportName = (request) => {
  try {
    const header = request.getResponseHeader('Content-Disposition');
    return header.split('filename=')[1].toLowerCase();
  } catch (err) {
    console.log(err);
    return 'exportExcel.xlsx';
  }
};

export const parseZipExportName = (request) => {
  try {
    const header = request.getResponseHeader('Content-Disposition');
    const matchArray = /"(\S*.zip)/.exec(header);
    return matchArray[1];
  } catch (error) {
    return 'export.zip';
  }
};

export const generateFileFromExport = (res, worklist = false) => {
  const { request } = res;
  const contentType = request.getResponseHeader('Content-Type');
  const fileName = worklist ? parseZipExportName(request) : parseTaskExportName(request);
  return { data: res.data, fileName, contentType };
};

export const mapKeyDisplayNameToTextValue = (items) =>
  items.map((item) => ({ text: item.displayName, value: item.key }));

export const mapKeyDisplayObjectToTextValue = (items) => {
  const retVal = [];

  for (const key in items) {
    if (items[key]) {
      retVal.push({ text: items[key].displayName, value: items[key].key });
    }
  }

  return retVal;
};

export const mapUserToTextValue = (items) =>
  items.map((item) => ({
    text: `${item.firstName} ${item.lastName}`,
    value: `${item.firstName} ${item.lastName}`
  }));

export const mapUserDisplayNameToTextValue = (items) =>
  items.map((item) => ({ text: item.displayName, value: item.id }));

export const extractDateAndMonth = (date) => {
  const x = `${date.getDate()}.${date.getMonth() + 1}.${date
    .getFullYear()
    .toString()
    .substr(2, 2)}`;
  return x;
};
/**
 *
 * @param {*} array
 * @returns object mapped as {key: {..object}}, example: {en_GB: {key: "en_GB", displayName: "English"}}
 */
export const arrayToObject = (array, keyField, additionalKey = '') =>
  array.reduce((obj, item) => {
    // eslint-disable-next-line no-param-reassign
    obj[item[keyField] + additionalKey] = item;
    return obj;
  }, {});

/**
 *
 * @param {*} array
 * @returns object mapped as {key: displayName}, example: {en_GB: "English"}
 */

export const arrayToKeyDisplayNameObject = (array) =>
  array.reduce((obj, item) => {
    // eslint-disable-next-line no-param-reassign
    obj[item.key] = item.displayName;
    return obj;
  }, {});

// image
// Path to the artwork is received from the API with {w} and {h} parameters ->
// when fetching, we are replacing them with default artwork size
export const setImageSize = (artworkUrl, width, height) =>
  artworkUrl.replace('{w}', width).replace('{h}', height);

// permissions
export const isAllowedByPermissions = (allowedPermissions, currentPermissions) => {
  if (!allowedPermissions || !allowedPermissions.length) return true;
  return allowedPermissions.some((p) => currentPermissions.indexOf(p) >= 0);
};

export const userHasPermission = (userPermissions, permissionToCheck) => {
  if (!userPermissions || !userPermissions.length) return true;
  return userPermissions.indexOf(permissionToCheck) >= 0;
};

export const userHasMultiplePermissions = (permissionsToCheck, userPermissions) => {
  if (!userPermissions || !userPermissions.length) return true; //TODO: Shouldn't this return false?
  return intersection(permissionsToCheck, userPermissions).length === permissionsToCheck.length;
};

// initials
export const getInitials = (fullName = '') => {
  const names = fullName.split(' ').filter((nameParts) => nameParts[0] !== '(');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const mapTaskStateToJobType = (taskState) => {
  let taskStateKey = taskState.key || taskState;

  switch (taskStateKey) {
    case TASK_STATES.submit_copy:
      return JOB_TYPES.writer;
    case TASK_STATES.external_review:
      return JOB_TYPES.editor;
    case TASK_STATES.adapt_internal_review:
    case TASK_STATES.onsite_review:
      return JOB_TYPES.reviewer;
    case TASK_STATES.review_copy:
      return JOB_TYPES.reviewer;
    case TASK_STATES.geo_lead_review:
      return JOB_TYPES.geo_lead;
    case TASK_STATES.adapt_review:
    case TASK_STATES.vendor_review:
    case TASK_STATES.adaptation_edit:
    case TASK_STATES.edit:
      return JOB_TYPES.editor;
    case TASK_STATES.write:
      return JOB_TYPES.writer;
    case TASK_STATES.review:
      return JOB_TYPES.reviewer;
    default:
      return '';
  }
};

/* eslint-disable */
export const createRandomUUID = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
/* eslint-enable */

export const getShelfNameLabel = (shelf) => {
  if (
    REGISTERED_SHELVES[shelf.shelfType] &&
    REGISTERED_SHELVES[shelf.shelfType].dynamicNavigationLabel
  ) {
    return shelf.subtitle;
  }
  return REGISTERED_SHELVES[shelf.shelfType].menuLabel || '';
};

export const getDataForContentType = (contentType, data) => {
  // TODO because superscalar is not returning valid data and UI is braking down because of it we have to go to default value of Shakespeare. Make sure that this is fixed, and that the right values for contentType are used
  // NOTE there is no need to have the mapper for contentType as we have the values in constant just update the constant and all is good
  switch (contentType.key) {
    case CONTENT_TYPES.appleMusicPlaylist:
    case CONTENT_TYPES.appleMusicAlbum:
    default:
      return null;
  }
};

export const getContentNamespace = (namespace) => {
  switch (namespace) {
    case WORKFLOW_NAMESPACES.musiccontentOld:
    case WORKFLOW_NAMESPACES.musiccontent:
      return 'musiccontent';
    case WORKFLOW_NAMESPACES.videocontentOld:
    case WORKFLOW_NAMESPACES.videocontent:
      return 'videocontent';
    case WORKFLOW_NAMESPACES.appscanvasOld:
    case WORKFLOW_NAMESPACES.appscanvas:
      return 'appscanvas';
    case WORKFLOW_NAMESPACES.copyworkflow:
      return 'copyworkflow';
    default:
      return '';
  }
};

export const formatUnderscoreToTitleCase = (text) => {
  const formattedText = text
    .replace(/([-,_,~,=,+])/g, ' ')
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  return formattedText;
};

export const parseAdamIds = (value) =>
  value
    .split(',')
    .map((id) => id.trim())
    .filter(Boolean);

export const getArrayAverage = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

export const flatten = (arr) =>
  arr.reduce(
    (flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  );

export const isTodaysDate = (date) => moment(date).isSame(moment(), 'day');

export const getStringLength = (text) => (text ? text.length : 0);

export const calculateMd5CheckSum = async (fileFromBlob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(fileFromBlob);
    reader.onloadend = () => {
      const wordArray = CryptoJS.lib.WordArray.create(reader.result);
      const hash = CryptoJS.MD5(wordArray).toString();
      resolve(hash);
    };
  });
};

export const getUniqueValueArrayByKey = (keyValueArray, propToFilterBy) => {
  const arrayOfAllFilteredByValues = keyValueArray.map((arrayItem) =>
    arrayItem[propToFilterBy] ? arrayItem[propToFilterBy].key : ''
  );
  const uniqueArrayByFilteredProp = Array.from(new Set(arrayOfAllFilteredByValues));
  return uniqueArrayByFilteredProp;
};

export const isEachArrayItemSame = (arrayToCheck) =>
  arrayToCheck.every((v) => v === arrayToCheck[0] && v !== undefined && v !== false && v !== null);

export const stripTextFromTags = (text) => {
  const decodedText = decodeHTMLEntities(text);
  return decodedText.replace(/(<([^>]+)>)/gi, '');
};

export const decodeHTMLEntities = (text) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(text, 'text/html');
  const decodedText = dom.body.textContent;

  return decodedText || text;
};

export const clone = (source, map = new WeakMap()) => {
  if (typeof source !== 'object' || source === null) {
    return source;
  }

  const type = Object.prototype.toString.call(source).slice(8, -1);
  let target = null;

  if (map.has(source)) {
    return map.get(source);
  }
  map.set(source, target);

  if (type === 'Object') {
    target = {};
    Object.keys(source).forEach((key) => {
      target[key] = clone(source[key], map);
    });
  } else if (type === 'Array') {
    target = [];
    source.forEach((value, index) => {
      target[index] = clone(value, map);
    });
  } else if (type === 'Map') {
    target = new Map();
    source.forEach((value, key) => {
      target.set(key, clone(value, map));
    });
  } else if (type === 'Set') {
    target = new Set();
    source.forEach((value) => {
      target.add(clone(value, map));
    });
  } else {
    switch (type) {
      case 'Boolean':
      case 'Number':
      case 'String':
      case 'Error':
      case 'Date':
        target = new source.constructor(source);
        break;
      case 'Function':
        break;
      default:
        return null;
    }
  }

  return target;
};

export const addTagsAndReplaceNbspSpecialCharacters = (
  contentWithSpecialCharactersInsteadOfQuillNbspTags
) => {
  const openingTag =
    '<my-tag-non-breaking class="mpe-quill-non-breaking mpe-quil-non=breaking-transparent" contenteditable="false">';
  const closingTag = '</my-tag-non-breaking>';

  const delimiterPattern = /([^\s>][A-z]+\u00A0[^\s<]+)/g;
  const contentWithSpecialCharactersInsteadOfQuillNbspTagsSplit =
    contentWithSpecialCharactersInsteadOfQuillNbspTags.split(delimiterPattern);
  let output = '';
  for (const token of contentWithSpecialCharactersInsteadOfQuillNbspTagsSplit) {
    let exec;
    if ((exec = /([^\s>][A-z]+\u00A0[^\s<]+)/.exec(token))) {
      const content = exec[0].replace(/\u00A0/g, ' ');
      output += `${openingTag}${content}${closingTag}`;
    } else {
      output += token;
    }
  }
  return output;
};

export const isValidWorkflowId = (workflowId) => {
  const regex = new RegExp(/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/);
  return regex.test(workflowId);
};
