// eslint-disable-next-line import/no-cycle
import ExportValidModal from './ExportValidModalContent';
import SimpleInfoModal from './SimpleInfoModalContent';

const modalTypes = {
  ExportValidModal,
  SimpleInfoModal
};

export default modalTypes;
