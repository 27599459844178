import { QualifiedUser } from '@providers/types-provider';

export enum TaskReassignActionTypes {
  GET_REASSIGN_LIST = 'GET_REASSIGN_LIST',
  GET_REASSIGN_LIST_REQUEST = 'GET_REASSIGN_LIST_REQUEST',
  GET_REASSIGN_LIST_SUCCESS = 'GET_REASSIGN_LIST_SUCCESS',
  CLEAR_REASSIGN_LIST = 'CLEAR_REASSIGN_LIST'
}

export interface GetReassignListRequestPayload {
  namespace: string;
  vendor: string | null;
  skill: {
    name: string;
    locales: string[];
    departments?: string[];
  };
}

export interface GetReassignListRequestAction {
  type: TaskReassignActionTypes.GET_REASSIGN_LIST_REQUEST;
  payload: GetReassignListRequestPayload;
}

export interface GetReassignListSuccessAction {
  type: TaskReassignActionTypes.GET_REASSIGN_LIST_SUCCESS;
  payload: QualifiedUser[];
}

export interface ClearReassignListAction {
  type: TaskReassignActionTypes.CLEAR_REASSIGN_LIST;
}

export type ReassignTaskActionTypes =
  | GetReassignListRequestAction
  | GetReassignListSuccessAction
  | ClearReassignListAction;
