import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import AlertBanner, { GlobalAlerts } from '@ui-components/alert-banner';
import { StyleConstants } from '@ui-components/style-constants';

interface Props {
  header: ReactNode;
  dashboard: ReactNode;
  filter: ReactNode;
  isFilterVisible?: boolean;
  isFilterBodyVisible?: boolean;
  alerts: [GlobalAlerts] | [];
}

// TODO: Need to eventually refactor and consolidate all the [app directory]/style/constants
// with @ui-components/style-constants

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding: 12px;
`;

const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const HeaderContainer = styled.div<{ header: ReactNode }>`
  width: 100%;
  height: ${StyleConstants.ui_components_navbar__height};
  position: relative;
  z-index: 10;
  ${(props) => !props.header && ` display: none;`}
  header {
    overflow: visible;
    height: 100%;
  }
`;

const FilterContainer = styled.div<{ isFilterVisible: boolean }>`
  width: 100%;
  z-index: 3;
  padding: 0 ${StyleConstants.ui_components_spacing_extra_large};
  display: ${(props) => (!props.isFilterVisible ? `none` : `flex`)};
  flex-direction: column;
  align-items: center;
`;

const TableDashboardLayout: FC<Props> = ({
  header,
  dashboard,
  filter,
  isFilterVisible = false,
  children,
  alerts
}) => {
  return (
    <Layout className="table-dashboard-layout">
      <HeaderContainer header={header}>{header}</HeaderContainer>
      {alerts.length ? (
        <BannerContainer>
          {alerts.map((alert: GlobalAlerts, index: number) => (
            <AlertBanner key={index} alert={alert} />
          ))}
        </BannerContainer>
      ) : null}
      <FilterContainer isFilterVisible={isFilterVisible}>{filter}</FilterContainer>
      {dashboard}
      {children}
    </Layout>
  );
};

export default TableDashboardLayout;
