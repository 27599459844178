import { isEnvironment } from '@helper-hooks/general-utils';

export const envBaseUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'https://wrkflow.app.local/';
  }

  return isEnvironment(['itms'])
    ? `https://${window.location.host}/`
    : process.env.REACT_APP_BASE_URL;
};

export const branchResolver = () => {
  const branch = process.env.REACT_APP_GIT_BRANCH;
  const splitBranch = branch.split('/');

  return splitBranch[1] ? splitBranch[1] : splitBranch[0];
};
