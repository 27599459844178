import React, { FC } from 'react';
import { Pages, NavMenuOptions } from '../../../../common/types/UserPreferences';
import { UserPreferencesModal } from '../UserPreferencesModal';
import { HotKeys } from '../components/Pages/HotKeys';
import { QueriesAndEscalations } from '../components/Pages/QueriesAndEscalations';

const Config = {
  title: 'Help',
  pages: {
    [Pages.HOT_KEYS]: {
      title: 'Hot Keys Guide',
      Component: HotKeys
    },
    [Pages.QUERIES_AND_ESCALATIONS]: {
      title: 'Queries and Escalations',
      Component: QueriesAndEscalations
    }
  }
} as NavMenuOptions;

interface Props {
  openModalStatus: { open: boolean; openTab: string };
  hide: () => void;
}

export const UserPreferencesHelpModal: FC<Props> = ({ openModalStatus, hide }) => {
  return (
    <UserPreferencesModal hide={hide} openModalStatus={openModalStatus} navMenuOptions={Config} />
  );
};
