import React, { FC, RefObject } from 'react';
import { Modal, Ref } from '@tidbits/react-tidbits';
import UserPreferencesContent from './components/UserPreferencesModal/UserPreferencesContent';
import './userPreferencesModal.scss';
import { NavMenuOptions } from '../../../common/types/UserPreferences';

interface ModalRef {
  ref: RefObject<HTMLDivElement>;
  setRef: (refObject: any) => void;
}

interface Props {
  navMenuOptions: NavMenuOptions;
  hide: () => void;
  openModalStatus: { open: boolean; openTab: string };
}

export const UserPreferencesModal: FC<Props> = ({ navMenuOptions, hide, openModalStatus }) => {
  const { open, openTab } = openModalStatus;
  return (
    <Modal.State>
      {() => (
        <Ref>
          {({ ref, setRef }: ModalRef) => (
            <>
              <Modal.Window
                open={open}
                closeModal={hide}
                maxWidth={800}
                width={900}
                focusableRef={ref}
                closeOnEscape={true}
                closeOnClickOutside={true}
              >
                <UserPreferencesContent
                  navMenuOptions={navMenuOptions}
                  openTab={openTab}
                  hide={hide}
                  setRef={setRef}
                />
              </Modal.Window>
            </>
          )}
        </Ref>
      )}
    </Modal.State>
  );
};
