import TRANSLATE_KEYS from '../translateKeys';

const translation = {
  [TRANSLATE_KEYS.welcome_to_react]: 'Welcome to React and react-i18next',
  [TRANSLATE_KEYS.loader]: 'Loading',
  [TRANSLATE_KEYS.you]: 'you',
  [TRANSLATE_KEYS.unexpected_error]: 'Unexpected Error',
  [TRANSLATE_KEYS.yes]: 'Yes',
  [TRANSLATE_KEYS.no]: 'No',
  [TRANSLATE_KEYS.agree]: 'Agree',
  [TRANSLATE_KEYS.disagree]: 'Disagree',
  [TRANSLATE_KEYS.reject]: 'Reject',
  [TRANSLATE_KEYS.approve]: 'Approve',

  /* ---> welcome page <--- */
  [TRANSLATE_KEYS.welcome_page.title]: 'Workflow',
  [TRANSLATE_KEYS.welcome_page.button_text]: 'Sign in with Apple',
  [TRANSLATE_KEYS.welcome_page.ok]: 'OK',
  [TRANSLATE_KEYS.welcome_page.something_went_wrong]: 'Something went wrong',
  [TRANSLATE_KEYS.welcome_page.try_logging_in_again]: 'Try logging in again',

  /* ---> navigation bar <--- */
  [TRANSLATE_KEYS.navigation_bar.search_input_placeholder]: 'Search',
  [TRANSLATE_KEYS.navigation_bar.logout]: 'Sign Out',
  [TRANSLATE_KEYS.navigation_bar.user_available]: 'Available',
  [TRANSLATE_KEYS.navigation_bar.user_unavailable]: 'Unavailable',
  [TRANSLATE_KEYS.navigation_bar.task_list]: 'Tasks',
  [TRANSLATE_KEYS.navigation_bar.user_list]: 'Users',
  [TRANSLATE_KEYS.navigation_bar.vendor_list]: 'Vendors',
  [TRANSLATE_KEYS.navigation_bar.analytics]: 'Analytics',

  /* ---> notifications panel <--- */
  [TRANSLATE_KEYS.notifications_panel.notifications]: 'Notifications',
  [TRANSLATE_KEYS.notifications_panel.mark_all_as_read]: 'Mark all as read',
  [TRANSLATE_KEYS.notifications_panel.unread]: 'Unread',
  [TRANSLATE_KEYS.notifications_panel.read]: 'Read',

  /* ---> filters <--- */
  [TRANSLATE_KEYS.filters.filtered_by]: 'Filtered by',
  [TRANSLATE_KEYS.filters.assignee]: 'Assignee',
  [TRANSLATE_KEYS.filters.locale]: 'Locale',
  [TRANSLATE_KEYS.filters.dueDate]: 'Due Date',
  [TRANSLATE_KEYS.filters.genres]: 'Genres',
  [TRANSLATE_KEYS.filters.statusChangeDate]: 'Status Change Date',
  [TRANSLATE_KEYS.filters.taskStatus]: 'Progress',
  [TRANSLATE_KEYS.filters.workflowType]: 'Workflow type',
  [TRANSLATE_KEYS.filters.clear_filter]: 'Clear filter',
  [TRANSLATE_KEYS.filters.select_date]: 'Select',
  [TRANSLATE_KEYS.filters.no_filter_selected]: '',
  [TRANSLATE_KEYS.filters.add_filter]: 'Add Filter',
  [TRANSLATE_KEYS.filters.add_filters]: 'Add Filters',
  [TRANSLATE_KEYS.filters.available]: 'Available',
  [TRANSLATE_KEYS.filters.me]: 'Assigned to me',
  [TRANSLATE_KEYS.filters.no_results]: 'No results found',

  /* ---> modals <--- */
  [TRANSLATE_KEYS.cancel_dialog]: 'Cancel',

  /* ---> task details page <--- */
  [TRANSLATE_KEYS.task_details_no_page.task_details_no_page_headline]:
    'This content is not available',
  [TRANSLATE_KEYS.task_details_no_page.task_details_no_page_button]: 'Back to Tasks',
  [TRANSLATE_KEYS.task_details_page.task_list_label]: 'Task list',
  [TRANSLATE_KEYS.task_details_page.all_cards.all_cards_table_title_locale]: 'LOCALE',
  [TRANSLATE_KEYS.task_details_page.equivalencies.tracks_label]: 'Tracks: ',
  [TRANSLATE_KEYS.task_details_page.equivalencies.reject_label]: 'Reject',
  [TRANSLATE_KEYS.task_details_page.equivalencies.genre_number_label]: 'Genres',
  [TRANSLATE_KEYS.task_details_page.all_cards.all_cards_table_title_spoken_names]: 'SPOKEN NAME',
  [TRANSLATE_KEYS.task_details_page.all_cards.all_cards_table_title_alias]: 'ALIAS',
  [TRANSLATE_KEYS.task_details_page.all_cards.all_cards_table_title_pronunciation]: 'PRONUNCIATION',
  [TRANSLATE_KEYS.task_details_page.all_cards.all_cards_history_new]: 'NEW',
  [TRANSLATE_KEYS.task_details_page.all_cards.all_cards_history_previous]: 'PREVIOUS',
  [TRANSLATE_KEYS.task_details_page.all_cards.all_cards_history_few_seconds_ago]: 'few seconds ago',
  [TRANSLATE_KEYS.task_details_page.decline]: 'Reject',
  [TRANSLATE_KEYS.task_details_page.submit]: 'Submit',
  [TRANSLATE_KEYS.task_details_page.preview]: 'Preview',
  [TRANSLATE_KEYS.task_details_page.update_spoken_name]: 'Update Spoken Name',
  [TRANSLATE_KEYS.task_details_page.enter_spoken_name_placeholder]: 'Enter spoken name',
  [TRANSLATE_KEYS.task_details_page.aliases_card_title]: 'Aliases',
  [TRANSLATE_KEYS.task_details_page.spoken_name_title]: 'Spoken Name',
  [TRANSLATE_KEYS.task_details_page.pronunciation_title]: 'Pronunciation',
  [TRANSLATE_KEYS.task_details_page.equivalencies_card_title]: 'Equivalents',
  [TRANSLATE_KEYS.task_details_page.localized_word_for_rejected]: 'Rejected',
  [TRANSLATE_KEYS.task_details_page.alias_type_title]: 'Alias Type',
  [TRANSLATE_KEYS.task_details_page.create_new_alias]: 'Create New',
  [TRANSLATE_KEYS.task_details_page.delete_alias_title]: 'Delete Alias',
  [TRANSLATE_KEYS.task_details_page.delete_alias_button_label]: 'Delete',
  [TRANSLATE_KEYS.task_details_page.delete_alias_modal_text]:
    "Are you sure that you want to delete this alias? This action can't be reverted.",
  [TRANSLATE_KEYS.task_details_page.information.information_assignee]: 'Assignee',
  [TRANSLATE_KEYS.task_details_page.information.reassign_task]: 'Edit',
  [TRANSLATE_KEYS.task_details_page.information.information_due_date]: 'Due Date',
  [TRANSLATE_KEYS.task_details_page.information.no_date]: 'None',
  [TRANSLATE_KEYS.task_details_page.information.edit_date]: 'Edit Date',
  [TRANSLATE_KEYS.task_details_page.information.information_day_left]: '1 day left',
  [TRANSLATE_KEYS.task_details_page.information.information_days_left]: '{{daysLeft}} days left',
  [TRANSLATE_KEYS.task_details_page.information.information_priority]: 'Priority',
  [TRANSLATE_KEYS.task_details_page.information.information_high]: 'High',
  [TRANSLATE_KEYS.task_details_page.information.information_normal]: 'Normal',
  [TRANSLATE_KEYS.task_details_page.information.information_prioritize]: 'Prioritize',
  [TRANSLATE_KEYS.task_details_page.information.information_prioritized]: 'Prioritized',
  [TRANSLATE_KEYS.task_details_page.information.information_copy_direction]: 'Copy Direction',
  [TRANSLATE_KEYS.task_details_page.information.information_locale]: 'Locale',
  [TRANSLATE_KEYS.task_details_page.information.information_adam_id]: 'Adam ID',
  [TRANSLATE_KEYS.task_details_page.information.information_task_type]: 'Workflow Type',
  [TRANSLATE_KEYS.task_details_page.information.information_status_change_date]: 'Last Update',
  [TRANSLATE_KEYS.task_details_page.information.information_adam_id]: 'Adam ID',
  [TRANSLATE_KEYS.task_details_page.information.information_artist_name]: 'Artist Name',
  [TRANSLATE_KEYS.task_details_page.information.information_genre]: 'Genre',
  [TRANSLATE_KEYS.task_details_page.information.information_modal_prioritize_title]:
    'Are you sure that you want to change the priority of this task?',
  [TRANSLATE_KEYS.task_details_page.information.information_modal_prioritize_content]:
    'This action is permanent and cannot be modified.',
  [TRANSLATE_KEYS.task_details_page.copy.copy_segments]: 'Segments',
  [TRANSLATE_KEYS.task_details_page.copy.name]: 'Name',
  [TRANSLATE_KEYS.task_details_page.copy.short_description]: 'Short Note',
  [TRANSLATE_KEYS.task_details_page.copy.long_description]: 'Long Note',
  [TRANSLATE_KEYS.task_details_page.copy.song]: 'Song',
  [TRANSLATE_KEYS.task_details_page.copy.album]: 'Album',
  [TRANSLATE_KEYS.task_details_page.copy.artist]: 'Artist',
  [TRANSLATE_KEYS.task_details_page.comments.send]: 'Send',
  [TRANSLATE_KEYS.task_details_page.comments.edit]: 'Edit',
  [TRANSLATE_KEYS.task_details_page.comments.reply]: 'Reply',
  [TRANSLATE_KEYS.task_details_page.comments.create_action_item]: 'Create Action item',
  [TRANSLATE_KEYS.task_details_page.comments.comments_title]: 'Comments',
  [TRANSLATE_KEYS.task_details_page.comments.comment]: 'Comment',
  [TRANSLATE_KEYS.task_details_page.comments.comment_field_empty]: 'Comment field is empty',
  [TRANSLATE_KEYS.task_details_page.comments.comment_edited]: 'Edited',
  [TRANSLATE_KEYS.task_details_page.comments.comment_resolved]: 'Resolved',
  [TRANSLATE_KEYS.task_details_page.comments.comment_resolved_by]: 'Resolved by',
  [TRANSLATE_KEYS.task_details_page.comments.comment_resolve]: 'Resolve',
  [TRANSLATE_KEYS.task_details_page.comments.create_action_item_from_comment]:
    'Create Action Item From This Comment',
  [TRANSLATE_KEYS.task_details_page.comments.create_action_item_from_comment_short]:
    'Create Action Item',
  [TRANSLATE_KEYS.task_details_page.tabs.task]: 'Task',
  [TRANSLATE_KEYS.task_details_page.tabs.inspect]: 'Inspect',
  [TRANSLATE_KEYS.task_details_page.tabs.empty_inspect_tab_description]:
    'Select Note Type to enter the inspect mode.',
  [TRANSLATE_KEYS.task_details_page.tabs.unavailable_inspect_tab_description]:
    'No confidence score available for this language',
  [TRANSLATE_KEYS.task_details_page.tabs.playlist_accordion_title]: 'Playlist',
  [TRANSLATE_KEYS.task_details_page.tabs.task_info_accordion_title]: 'Task Info',
  [TRANSLATE_KEYS.task_details_page.tabs.history_accordion_title]: 'History',
  [TRANSLATE_KEYS.task_details_page.tabs.confidence_score_accordion_title]: 'Confidence Score',
  [TRANSLATE_KEYS.task_details_page.tabs.shelf_info_accordion_title]: 'Info',
  [TRANSLATE_KEYS.task_details_page.tabs.shelf_info_song_title]: 'Song',
  [TRANSLATE_KEYS.task_details_page.tabs.shelf_info_album_title]: 'Album',
  [TRANSLATE_KEYS.task_details_page.tabs.shelf_info_artist_title]: 'Artist',
  [TRANSLATE_KEYS.task_details_page.tabs.spoken_names_accordion_title]: 'Spoken Names',
  [TRANSLATE_KEYS.task_details_page.tabs.aliases_accordion_title]: 'Aliases',
  [TRANSLATE_KEYS.task_details_page.tabs.pronunciation_accordion_title]: 'Pronunciation',
  [TRANSLATE_KEYS.task_details_page.tabs.comments_accordion_title]: 'Comments',
  [TRANSLATE_KEYS.task_details_page.name_shelf.name_shelf_label_edited]: 'Edited',
  [TRANSLATE_KEYS.task_details_page.name_shelf.name_shelf_label_auto_generated]: 'Autogenerated',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_show_more_details]: 'More',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_show_less_details]: 'Less',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_changed]: ' changed',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_priority_normal]: ' Normal',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_priority_prioritized]:
    ' Prioritized',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_changed_from]: ' from ',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_changed_to]: ' to ',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_changed_by]: ' by ',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_rejected]: 'REJECTED',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_assigned]: 'ASSIGNED',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_assignee_changed]:
    'ASSIGNEE CHANGED',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_status_changed]:
    'STATUS CHANGED',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_due_date_changed]:
    'DUE DATE CHANGED',
  [TRANSLATE_KEYS.task_details_page.task_history.task_history_item_status_priority_changed]:
    'PRIORITY CHANGED',
  [TRANSLATE_KEYS.task_details_page.confidence_score.calculate_confidence_score]:
    'Calculate Confidence Score',
  [TRANSLATE_KEYS.task_details_page.confidence_score.confidence_score_error]: 'Try again',
  [TRANSLATE_KEYS.task_details_page.confidence_score.calculating_confidence_score]:
    'Calculating Confidence Score',
  [TRANSLATE_KEYS.task_details_page.confidence_score.confidence_score]: 'Confidence Score:',
  [TRANSLATE_KEYS.task_details_page.confidence_score.suggested_change]: 'Suggested change: ',
  [TRANSLATE_KEYS.task_details_page.confidence_score.apply_issue]: 'Apply',
  [TRANSLATE_KEYS.task_details_page.shelf_information.artist_label]: 'Artist',
  [TRANSLATE_KEYS.task_details_page.shelf_information.about_label]: 'About',
  [TRANSLATE_KEYS.task_details_page.shelf_information.born_label]: 'Born',
  [TRANSLATE_KEYS.task_details_page.shelf_information.hometown_label]: 'Hometown',
  [TRANSLATE_KEYS.task_details_page.shelf_information.genre_label]: 'Genre',
  [TRANSLATE_KEYS.task_details_page.shelf_information.adam_id_label]: 'Adam ID',
  [TRANSLATE_KEYS.task_details_page.shelf_information.song_label]: 'Song Name',
  [TRANSLATE_KEYS.task_details_page.shelf_information.album_label]: 'Album',
  [TRANSLATE_KEYS.task_details_page.shelf_information.year_label]: 'Year',
  [TRANSLATE_KEYS.task_details_page.rich_text.leave_comment]: 'Leave a comment',
  [TRANSLATE_KEYS.task_details_page.show_all]: 'Show All',
  [TRANSLATE_KEYS.task_create_modal.create_button]: 'Create',
  [TRANSLATE_KEYS.task_create_modal.song_adam_id]: 'Song Adam ID',
  [TRANSLATE_KEYS.task_create_modal.song_adam_id_placeholder]: 'Paste Adam ID (comma separated)',
  [TRANSLATE_KEYS.task_create_modal.invalid_song_adam_id]:
    'Please enter a valid Adam ID (comma separated)',
  [TRANSLATE_KEYS.task_create_modal.set_due_date]: 'Set Due Date',
  [TRANSLATE_KEYS.task_create_modal.locale_label]: 'Locale',
  [TRANSLATE_KEYS.task_create_modal.bypass_availability_check]: 'Bypass Availability Check',
  [TRANSLATE_KEYS.task_create_modal.bypass_availability_notification]:
    'Bypass selection will result in the bigger expenses for the editorial team',
  [TRANSLATE_KEYS.task_create_modal.scheduled_task_creation_title]: 'Scheduled Task Creation',
  [TRANSLATE_KEYS.task_create_modal.scheduled_task_creation_text]:
    'Tasks are being generated at the moment. You will receive an email when the process is done.',
  [TRANSLATE_KEYS.task_create_modal.scheduled_task_creation_button]: 'OK',
  [TRANSLATE_KEYS.task_create_modal.calendar_placeholder_label]: 'Date Not Set',

  [TRANSLATE_KEYS.music_player.music_player_log_in]: ' Sign In',
  [TRANSLATE_KEYS.music_player.music_player_log_out]: 'Log out',
  [TRANSLATE_KEYS.music_player.music_player_unavailable]: 'Music Kit player is not available',

  /* ---> advance task modal <--- */
  [TRANSLATE_KEYS.rating_stars.rating_stars_insufficient]: 'Insufficient',
  [TRANSLATE_KEYS.rating_stars.rating_stars_sufficient]: 'Sufficient',
  [TRANSLATE_KEYS.rating_stars.rating_stars_good]: 'Good',
  [TRANSLATE_KEYS.rating_stars.rating_stars_great]: 'Great',
  [TRANSLATE_KEYS.rating_stars.rating_stars_excellent]: 'Excellent',
  [TRANSLATE_KEYS.advance_task.modal_text_author]:
    'You are about to send this task to an editor for review. Write a short remark in the field below if needed.',
  [TRANSLATE_KEYS.advance_task.modal_text_editor_shakespeare]:
    'Rate your experience with this writer',
  [TRANSLATE_KEYS.advance_task.modal_text_reviewer]:
    'After you submit, it will be marked for publishing. You can export finished tasks from the task list screen. This action is final and irreversible.',

  /* ---> reject task modal <--- */
  [TRANSLATE_KEYS.reject_page.reject_task_textarea_placeholder_author]:
    'Why are you returning this task to be re-assigned?',
  [TRANSLATE_KEYS.reject_page.reject_task_textarea_placeholder_editor]:
    'Why are you returning this task to the writer?',
  [TRANSLATE_KEYS.reject_page.reject_task_textarea_placeholder_reviewer]:
    'Why are you rejecting this task?',
  [TRANSLATE_KEYS.reject_page.reject_task_modal_headline]: 'Return Task',
  [TRANSLATE_KEYS.reject_page.reject_task_modal_right_button_label]: 'Reject',
  [TRANSLATE_KEYS.reject_page.comment_error]: 'Comment is mandatory',
  [TRANSLATE_KEYS.reject_page.reject_task_modal_text]:
    'Tell us why are you rejecting this task. Let the manager know if there is a problem with the task.',
  [TRANSLATE_KEYS.reject_page.rejection_reason]: 'Rejection reason',
  [TRANSLATE_KEYS.reject_page.rejection_reason_headline]: 'Reject task',
  [TRANSLATE_KEYS.reject_page.rejection_reason_add]: 'Add rejection reason',
  [TRANSLATE_KEYS.reject_page.rejection_reason_placeholder]: 'Choose rejection reason',
  /* ---> resolve task modal <--- */
  [TRANSLATE_KEYS.resolve_page.resolve_task_textarea_placeholder_author]:
    'Add a comment for the editor',
  [TRANSLATE_KEYS.resolve_page.resolve_task_textarea_placeholder_editor]:
    'Add a comment for the reviewer',
  [TRANSLATE_KEYS.resolve_page.resolve_task_textarea_placeholder_reviewer]: 'Add a comment',
  [TRANSLATE_KEYS.resolve_page.resolve_task_modal_headline_author]: 'Provide Feedback',
  [TRANSLATE_KEYS.resolve_page.resolve_task_modal_headline_editor_shakespeare]: 'Provide Feedback',
  [TRANSLATE_KEYS.resolve_page.resolve_task_modal_headline_reviewer]: 'Submit for Publishing',
  [TRANSLATE_KEYS.resolve_page.resolve_task_modal_right_button_label]: 'Submit',
  [TRANSLATE_KEYS.resolve_page.resolve_task_modal_right_button_label_rate_shakespeare]: 'Rate',
  [TRANSLATE_KEYS.resolve_page.comment_error]: 'Comment is mandatory',

  /* ---> confidence score modal <--- */
  [TRANSLATE_KEYS.confidence_score.confidence_score_modal_info_content]:
    'Confidence score should be higher than 85. Modify your text and recalculate the confidence score. If you wish to proceed, please write a short explanation.',
  [TRANSLATE_KEYS.confidence_score.confidence_score_modal_title]:
    'Confidence score for one or more note types is too low',
  [TRANSLATE_KEYS.confidence_score.confidence_score_modal_right_button_label]: 'Confirm',
  [TRANSLATE_KEYS.confidence_score.confidence_score_modal_left_button_label]: 'Cancel',
  [TRANSLATE_KEYS.confidence_score.confidence_comment_required_text]: 'Please add comment',
  [TRANSLATE_KEYS.confidence_score.calculate_confidence_score_modal_title]:
    'Confidence score must be calculated before the submission',
  [TRANSLATE_KEYS.confidence_score.calculate_confidence_score_modal_content]:
    'All confidence scores must be calculated before task submission.',
  [TRANSLATE_KEYS.confidence_score.calculate_confidence_score_modal_button]: 'Calculate',

  /* ---> export tasks modal <--- */
  [TRANSLATE_KEYS.export_valid.export_all_tasks]: 'all',
  [TRANSLATE_KEYS.export_valid.export_valid_one_task_headline]:
    'Are you sure you want to export 1 task?',
  [TRANSLATE_KEYS.export_valid.export_valid_headline]:
    'Are you sure you want to export {{numberOfTasksToExport}} tasks?',
  [TRANSLATE_KEYS.export_valid.export_valid_one_task_sub_headline]:
    'Exported task will be generated and downloaded in .xlsx format',
  [TRANSLATE_KEYS.export_valid.export_valid_sub_headline]:
    'Exported tasks will be generated and downloaded in .xlsx format',
  [TRANSLATE_KEYS.export_valid.export_and_update_valid_sub_headline]:
    'Exported tasks will be updated to Exported Status and downloaded in .xlsx format.',

  [TRANSLATE_KEYS.export.export_success_button_label]: 'Export',

  /* ---> reassign task modal <--- */
  [TRANSLATE_KEYS.reassign_user.reassign_user_title]: 'Choose new assignee',
  [TRANSLATE_KEYS.reassign_user.reassign_user_title_multiple]: 'Choose new assignee',
  [TRANSLATE_KEYS.reassign_user.reassign_user_button]: 'Choose',

  /* ---> add new user modal <--- */
  [TRANSLATE_KEYS.user_details_page.invalid_mail]: 'Invalid Email',
  [TRANSLATE_KEYS.user_details_page.invalid_first_name]: 'Invalid First Name',
  [TRANSLATE_KEYS.user_details_page.invalid_last_name]: 'Invalid Last Name',
  [TRANSLATE_KEYS.user_details_page.something_went_wrong]: 'Something went wrong',
  [TRANSLATE_KEYS.user_details_page.email]: 'Email',
  [TRANSLATE_KEYS.user_details_page.email_placeholder]: 'Enter Email Address',
  [TRANSLATE_KEYS.user_details_page.first_name]: 'First Name',
  [TRANSLATE_KEYS.user_details_page.first_name_placeholder]: 'Enter First Name',
  [TRANSLATE_KEYS.user_details_page.last_name]: 'Last Name',
  [TRANSLATE_KEYS.user_details_page.last_name_placeholder]: 'Enter Last Name',
  [TRANSLATE_KEYS.user_details_page.invalid_job_type]: 'Invalid Job Type',
  [TRANSLATE_KEYS.user_details_page.add_user_headline]: 'Add New User',
  [TRANSLATE_KEYS.user_details_page.add_user_button]: 'Add',
  [TRANSLATE_KEYS.user_details_page.assign_job_type_header]: 'Select Job Type(s)',
  [TRANSLATE_KEYS.user_details_page.assign_job_type_header_placeholder]: 'Choose Job Type(s)',
  [TRANSLATE_KEYS.user_details_page.select_languages]: 'Select Languages',
  [TRANSLATE_KEYS.user_details_page.select_languages_placeholder]: 'Choose Languages',
  [TRANSLATE_KEYS.user_details_page.invalid_languages]: 'Invalid Languages',
  [TRANSLATE_KEYS.user_details_page.select_content_type]: 'Select Content Type',
  [TRANSLATE_KEYS.user_details_page.select_content_type_placeholder]: 'Choose Content Type',
  [TRANSLATE_KEYS.user_details_page.invalid_content_type]: 'Invalid Content Type',
  [TRANSLATE_KEYS.user_details_page.select_media]: 'Select Media Type',
  [TRANSLATE_KEYS.user_details_page.select_media_placeholder]: 'Choose Media Type',
  [TRANSLATE_KEYS.user_details_page.invalid_media]: 'Invalid Media Type',
  [TRANSLATE_KEYS.user_details_page.select_genre]: 'Select Genre',
  [TRANSLATE_KEYS.user_details_page.select_genre_placeholder]: 'Choose Genre',
  [TRANSLATE_KEYS.user_details_page.invalid_genre]: 'Invalid Genre',
  [TRANSLATE_KEYS.user_details_page.availability_header]: 'Available For Work',
  [TRANSLATE_KEYS.user_details_page.invalid_availability]: 'Invalid Availability',
  [TRANSLATE_KEYS.user_details_page.select_vendor_organization]: 'Select Vendor Organization',
  [TRANSLATE_KEYS.user_details_page.select_vendor_organization_placeholder]: 'Select Vendor',
  [TRANSLATE_KEYS.user_details_page.invalid_vendor_organization]: 'Invalid Vendor Organization',
  [TRANSLATE_KEYS.user_details_page.personal_info_header]: 'Personal Info',
  [TRANSLATE_KEYS.user_details_page.permissions_header]: 'Permissions',

  /* ---> change user availability modal <--- */
  [TRANSLATE_KEYS.user_availability.user_availability_message_header]: 'Change Your Availability',
  [TRANSLATE_KEYS.user_availability.user_availability_message_content]:
    "Change your availability if you don't want to receive new tasks.",
  [TRANSLATE_KEYS.user_availability.user_availability_button]: 'Confirm',

  /* ---> edit user modal <--- */
  [TRANSLATE_KEYS.edit_user.edit_user_headline]: 'Edit user',
  [TRANSLATE_KEYS.edit_user.edit_user_button]: 'Save',

  /* ---> delete user modal <--- */
  [TRANSLATE_KEYS.delete_user_button_label]: 'Delete',
  [TRANSLATE_KEYS.user_details_page.delete_confirm_message_header]:
    'Are you sure you want to delete {{name}}?',
  [TRANSLATE_KEYS.user_details_page.delete_confirm_message_content]:
    'This user will be removed from your company. User will no longer have access to any items shared by you.',
  [TRANSLATE_KEYS.delete_not_possible.delete_not_possible_headline]:
    'Delete for current user is not possible.',
  [TRANSLATE_KEYS.delete_not_possible.delete_not_possible_sub_headline]:
    'Selected user cannot be deleted. There are some tasks which selected users is currently assigned to. Please reassign these tasks and try again.',

  /* ---> VENDORS <--- */
  /* ---> vendor list page <--- */
  [TRANSLATE_KEYS.vendor_details_page.empty_vendor_list]: "Empty Vendor's List",
  [TRANSLATE_KEYS.vendor_details_page.add_vendor_sub_headline]:
    'Use Add Vendor function to add new vendors',
  [TRANSLATE_KEYS.vendor_details_page.table_headers.name]: 'Name',
  [TRANSLATE_KEYS.vendor_details_page.table_headers.numberOfAuthors]: '# of authors',
  [TRANSLATE_KEYS.vendor_details_page.table_headers.numberOfEditors]: '# of editors',

  /* ---> add new vendor modal <--- */
  [TRANSLATE_KEYS.vendor_details_page.invalid_name]: 'Invalid Name',
  [TRANSLATE_KEYS.vendor_details_page.something_went_wrong]: 'Something went wrong',
  [TRANSLATE_KEYS.vendor_details_page.name]: 'Name',
  [TRANSLATE_KEYS.vendor_details_page.assign_languages]: 'Assign languages to vendor',
  [TRANSLATE_KEYS.vendor_details_page.select_languages]: 'Select Languages',
  [TRANSLATE_KEYS.vendor_details_page.invalid_languages]: 'Invalid languages',
  [TRANSLATE_KEYS.vendor_details_page.add_vendor_headline]: 'Add new vendor',
  [TRANSLATE_KEYS.vendor_details_page.add_vendor_button]: 'Add vendor',

  /* ---> edit vendor modal <--- */
  [TRANSLATE_KEYS.vendor_details_page.edit_vendor_headline]: 'Edit vendor',
  [TRANSLATE_KEYS.edit_user.edit_user_button]: 'Save',

  /* ---> date picker <--- */
  [TRANSLATE_KEYS.date_picker.confirm_button_label]: 'Select',

  /* ---> toast messages <--- */
  [TRANSLATE_KEYS.toast_messages.error.error_on_advance_task]: 'Task advance failed.',
  [TRANSLATE_KEYS.toast_messages.error.error_on_reassign_task]: 'Task reassign failed.',
  [TRANSLATE_KEYS.toast_messages.successful.successful_advanced_task]:
    'Task advanced successfully.',
  [TRANSLATE_KEYS.toast_messages.successful.successful_created_user]:
    'You just added the user {{userMail}}',
  [TRANSLATE_KEYS.toast_messages.successful.successful_edited_user]:
    'User {{user}} successfully edited.',
  [TRANSLATE_KEYS.toast_messages.successful.successful_created_vendor]:
    'You just added the vendor {{vendorName}}',
  [TRANSLATE_KEYS.toast_messages.successful.successful_edited_vendor]:
    'Vendor {{vendorName}} successfully edited.',
  [TRANSLATE_KEYS.toast_messages.successful.successful_deleted_user]: 'User deleted successfully.',
  [TRANSLATE_KEYS.toast_messages.successful.successful_reassign_task]:
    'Task successfully reassigned.',
  [TRANSLATE_KEYS.toast_messages.successful.successful_reassign_tasks]:
    'Tasks successfully reassigned.',
  [TRANSLATE_KEYS.toast_messages.warning.forbidden]: 'Forbidden',
  [TRANSLATE_KEYS.toast_messages.error.error_something_went_wrong]: 'Something went wrong',
  [TRANSLATE_KEYS.toast_messages.error.error_on_confidence_score]:
    'Something went wrong with confidence score calculation. Try again in a few moments.',
  [TRANSLATE_KEYS.toast_messages.error.error_on_device]:
    'Recording can be performed on iPad devices only.',
  [TRANSLATE_KEYS.toast_messages.error.too_many_permissions]:
    'There is a problem with the number of permissions associated to your account. Contact support for help.',

  /* ---> reassign modal <--- */
  [TRANSLATE_KEYS.reassign_modal.reassign_modal_unassign_task_title]: 'Unassign Task',
  [TRANSLATE_KEYS.reassign_modal.reassign_modal_unassign_task_text]:
    'Unassigned tasks will be returned to the general queue and automatically assigned to the best available user.',
  [TRANSLATE_KEYS.audio_recording.record]: 'Record',
  [TRANSLATE_KEYS.audio_recording.recording]: 'Stop Recording',
  [TRANSLATE_KEYS.audio_recording.processing]: 'Processing'
};

export default translation;
