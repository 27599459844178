import { IconType } from '@ui-components/icon-template';
import { WorkflowUser } from '@providers/types-provider';

import {
  Task,
  Shelf,
  KeyDisplayName,
  Localization,
  PlayerData,
  Queue,
  ScoresAndIssues,
  Issue,
  Notification,
  ShelfScoreStatus,
  TaskConfidenceScore,
  CollaborationSummary,
  CreateComment,
  UpdateComment,
  TaskHistoryItem
} from './types';
// @ts-ignore
import Highlight from '../components/task/components/Quill/quillRichTextEditorCustomBlots/HighlightBlot';
import { DESCRIPTION_STRINGS } from '.';
import { ApiCreateComment } from '../utils/AntiCorruption/models/collaboration/ApiCreateComment';
import { ApiUpdateComment } from '../utils/AntiCorruption/models/collaboration/ApiUpdateComment';
import { ApiLocalization } from '../utils/AntiCorruption/models/util/ApiLocalization';
import { Assignee } from './types/Assignee';

export const KEY_DISPLAY_NAME = {
  key: 'someKey',
  displayName: 'Display Name'
} as KeyDisplayName;

export const KEY_DISPLAY_NAME_CONTENT_TYPE = {
  key: 'APPLE_MUSIC_ALBUM',
  displayName: 'Display Name'
} as KeyDisplayName;

export const LOCALIZATION = {
  id: '23424235345',
  lastModified: '2019-03-05T14:30Z',
  referenceLocale: KEY_DISPLAY_NAME,
  referenceText: 'reference text',
  shelfDisplayTitle: 'Header',
  shelfLocalizationHistory: [],
  shelfText: 'This is a good playlist',
  shelfAutogeneratedText: 'This is autogenerated good playlist',
  shelfTextHighlights: [],
  shelfTextToUpdate: ''
} as Localization;

export const LOCALIZATION_WITH_EMPTY_SHELF_TEXT = {
  ...LOCALIZATION,
  shelfText: '',
  shelfAutogeneratedText: ''
} as Localization;

export const COLLABORATION_SUMMARY = {
  totalCount: 5,
  unreadCount: 0,
  sessionId: '',
  lastMessage: 'UNKNOWN_LAST_MESSAGE'
} as CollaborationSummary;

export const SHELF = {
  id: 'qwrwteyrut',
  shelfName: 'Name',
  shelfType: DESCRIPTION_STRINGS.name,
  conversation: [],
  collaborationSummary: COLLABORATION_SUMMARY,
  localization: LOCALIZATION,
  isConfidenceScoreSupported: true
} as Shelf;

export const SHELF_WITH_EMPTY_SHELF_TEXT = {
  ...SHELF,
  localization: LOCALIZATION_WITH_EMPTY_SHELF_TEXT
} as Shelf;

export const HIGHLIGHTS = [] as Highlight;

export const SHELVES = [
  SHELF,
  {
    id: 'asdfghjkl',
    shelfName: 'Short Description',
    shelfType: DESCRIPTION_STRINGS.short,
    conversation: [],
    localization: {} as Localization,
    isConfidenceScoreSupported: true
  },
  {
    id: 'zxcvbnm',
    shelfName: 'Long Description',
    shelfType: DESCRIPTION_STRINGS.long,
    conversation: [],
    localization: {} as Localization,
    isConfidenceScoreSupported: true
  }
] as Shelf[];

export const ADVANCE_OPTIONS = [
  { key: 'REJECT', displayName: 'Reject' },
  { key: 'SUBMIT', displayName: 'Submit' }
];

export const USER = {
  id: '001',
  name: 'Emily Smith',
  email: 'emily.smith@company.com',
  username: 'EmilySmith',
  userRealm: 'Authzilla',
  fullyQualifiedId: 'Authzilla:001',
  permissions: [
    'VACATION_MODE',
    'ADD_ACTION_ITEM',
    'RESOLVE_ACTION_ITEM',
    'ADD_VIEW_INTERNAL_REVIEW'
  ],
  jobTypes: [],
  accessToken: ''
} as WorkflowUser;

export const ASSIGNEE = {
  displayName: 'ASSIGNEE_DISPLAY_NAME',
  assigneeType: 'ASSIGNEE_TYPE',
  identifier: '001'
} as Assignee;

export const SHELF_SCORE_STATUS = {
  shelfId: '1',
  status: KEY_DISPLAY_NAME
} as ShelfScoreStatus;

export const TASK_CONFIDENCE_SCORE = {
  taskId: '1',
  workflowId: '1',
  status: KEY_DISPLAY_NAME,
  shelfScoreStatus: [SHELF_SCORE_STATUS]
} as TaskConfidenceScore;

export const TASK = {
  id: '135346',
  workflowId: '12345',
  assignee: ASSIGNEE,
  name: 'awesome playlist',
  adamId: '23424235',
  locale: KEY_DISPLAY_NAME,
  dueDate: '2019-03-05T14:30:00.000+0000',
  statusKey: 'EXTERNAL_ASSIGNED',
  priority: true,
  statusChangeDate: '2019-03-05T14:30:00.000+0000',
  contentType: KEY_DISPLAY_NAME_CONTENT_TYPE,
  taskMode: 'NORMAL',
  copyDirection: 'foo',
  contentName: 'awesome playlist',
  contentMetadataGlobalId: 'pl.b553cd52fbf249d1b9b0bfe3e0cff543',
  vendor: 'CompanyX',
  rating: 4.5,
  shelves: [SHELF],
  expirationDate: '2019-09-09T14:30:00.000+0000',
  mediaType: 'MUSIC',
  genres: KEY_DISPLAY_NAME,
  isLocaleConfidenceScoreEnabled: true,
  advanceOptions: [KEY_DISPLAY_NAME],
  taskState: KEY_DISPLAY_NAME
} as Task;

export const TASKS = [
  TASK,
  {
    id: 654321,
    name: 'awesome playlist 2',
    adamId: '12331213',
    locale: KEY_DISPLAY_NAME,
    dueDate: '2019-04-05T14:30:00.000+0000',
    statusKey: 'EXTERNAL_ASSIGNED',
    priority: true,
    statusChangeDate: '2019-04-05T14:30:00.000+0000',
    contentType: KEY_DISPLAY_NAME,
    copyDirection: 'foo',
    contentName: 'awesome playlist',
    contentMetadataGlobalId: 'pl.ad435gcf4vu3bh345jhb45ugv5jb4hk345',
    vendor: 'CompanyX',
    rating: 4.5,
    shelves: SHELVES,
    expirationDate: '2019-10-09T14:30:00.000+0000',
    mediaType: 'MUSIC',
    genres: [KEY_DISPLAY_NAME]
  }
] as Task[];

export const QUEUE = {
  items: [
    {
      attributes: {
        artistName: 'artistName',
        name: 'name',
        artwork: { url: 'url' }
      },
      artworkURL: 'artworkURL',
      artistName: 'artistName',
      playlistArtworkURL: 'playlistArtworkURL'
    }
  ],
  position: 1
} as Queue;

export const PLAYER_DATA = {
  isPlayerSuccessfullyLoaded: true,
  isPlaying: true,
  isPreviousPlayable: false,
  isNextPlayable: true,
  isAuthorized: true,
  loading: false,
  currentPlaybackDuration: 1,
  currentPlaybackTime: 0.2,
  queue: QUEUE,
  artistName: 'Eminem',
  songName: 'Lose Yourself',
  imageToSet: '',
  handleAuthorization: () => {},
  currentSongIndex: 1,
  changeToSong: () => {},
  onNextClick: () => {},
  onPrevClick: () => {},
  onPauseClick: () => {},
  onPlayClick: () => {}
} as PlayerData;

export const ISSUE = {
  category: {
    key: 'MISSPELLING',
    displayName: 'misspelling'
  },
  issueId: '9bc0807e-c525-4041-8107-ca4a97fb10b0',
  startIndex: 761,
  endIndex: 768,
  issueType: {
    key: 'misspelling',
    displayName: 'misspelling'
  },
  suggestions: ['Grammy'],
  description: 'Possible misspelling found'
} as Issue;

export const ISSUE2 = {
  category: {
    key: 'GRAMMAR',
    displayName: 'grammar'
  },
  issueId: '5a018b11-9793-4e0e-a79d-e7c1b61c637f',
  startIndex: 761,
  endIndex: 768,
  issueType: {
    key: 'GRAMMAR',
    displayName: 'grammar'
  },
  suggestions: ['was not'],
  description: 'Wikipedia style. Replace "wasn\'t" with <suggestion>was not</suggestion>.'
} as Issue;

export const SCORES_AND_ISSUES = {
  status: 'SUCCESS',
  scorePassed: true,
  shelfLocalizationId: '1235',
  score: 87,
  issues: [ISSUE, ISSUE2]
} as ScoresAndIssues;

export const SHELF_WITH_SCORE = {
  ...SHELF,
  scoresAndIssues: SCORES_AND_ISSUES
} as Shelf;

export const NOTIFICATION_UNREAD = {
  id: '1234',
  eventStatus: {
    displayName: 'Unread',
    key: 'UNREAD'
  } as KeyDisplayName,
  eventType: {
    displayName: 'Mention',
    key: 'MENTION'
  } as KeyDisplayName,
  message: 'You were mentioned',
  initiator: USER,
  timeStamp: '2019-09-09T14:30:00.000Z'
} as Notification;

export const NOTIFICATION_READ = {
  id: '1234',
  eventStatus: {
    displayName: 'Read',
    key: 'READ'
  } as KeyDisplayName,
  eventType: {
    displayName: 'Task status change',
    key: 'TASK_STATUS_CHANGE'
  } as KeyDisplayName,
  message: 'You were mentioned',
  initiator: USER,
  timeStamp: '2019-09-09T14:30:00.000Z'
} as Notification;
export const INFO_MODAL_ICON: { iconName: IconType; iconPosition: {} } = {
  iconName: 'export',
  iconPosition: {
    x: 0,
    y: 0,
    panX: 10,
    panY: 10
  }
};

export const WORKFLOW_ID: string = '123456';

export const CREATE_COMMENT: CreateComment = {
  comment: 'New comment',
  parentId: '123',
  sessionId: '456',
  contentSnapshot: 'some snapshot',
  collaborationType: { key: 'REGULAR', displayName: 'Regular' },
  collaborationStatus: { key: 'STATUS', displayName: 'Status' }
};

export const API_CREATE_COMMENT: ApiCreateComment = {
  workflowId: WORKFLOW_ID,
  sessionId: '456',
  parentId: '123',
  contentSnapshot: 'some snapshot',
  text: 'New comment',
  type: { key: 'REGULAR', displayName: 'Regular' },
  highlights: ''
};

export const UPDATE_COMMENT: UpdateComment = {
  id: '123',
  comment: 'Dummy comment',
  workflowId: '789',
  sessionId: '456'
};

export const API_UPDATE_COMMENT: ApiUpdateComment = {
  id: '123',
  workflowId: WORKFLOW_ID,
  sessionId: '456',
  text: 'Dummy comment',
  actionStatus: undefined
};

export const API_LOCALIZATION: ApiLocalization = {
  id: '23424235345',
  shelfTexType: KEY_DISPLAY_NAME,
  shelfDisplayTitle: 'Header',
  shelfText: 'This is a good playlist',
  autoGeneratedText: 'This is autogenerated good playlist',
  shelfTextToUpdate: '',
  referenceLocale: KEY_DISPLAY_NAME,
  referenceText: 'reference text',
  shelfLocalizationHistory: [],
  lastModified: '2019-03-05T14:30Z',
  createdAt: '',
  shelfTextHash: ''
};

export const INFO_MODAL_CONTENT = {
  title: 'New modal',
  children: 'This is a test modal'
};

export const MODAL_BUTTON_BOTTOM = {
  closeModal: () => {},
  onSuccess: () => {},
  successLabel: 'Success',
  closeLabel: 'Cancel'
};

export const INFO_MODAL = {
  ...INFO_MODAL_ICON,
  ...INFO_MODAL_CONTENT,
  ...MODAL_BUTTON_BOTTOM
};

export const taskHistoryItem: TaskHistoryItem = {
  user: {
    identifier: 'index',
    email: 'email',
    firstName: 'firstName',
    lastName: 'lastName',
    displayName: 'firstName lastName',
    vendorName: 'Company Awesome'
  },
  when: 179325784,
  field: {
    key: 'STATUS',
    displayName: 'Status'
  },
  fromValue: 'Review',
  toValue: 'Rejected',
  comment: 'I do not like the tone of the short description',
  operation: {
    key: 'UPDATE',
    displayName: 'Update'
  }
};
