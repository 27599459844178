"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const axiosInstance_1 = require("./axiosInstance");
const serverSentEvents = {
    publish: (taskId, schemaType, payload, eventName) => {
        return axiosInstance_1.axiosInstance
            .post(`/api/server-sent-events/v1/sse/publish/${taskId}`, {
            eventName: eventName || 'message',
            schemaType,
            payload
        })
            .then((res) => res.data);
    }
};
exports.default = serverSentEvents;
