import React, { useState } from 'react';
import { Panel, Text, Box } from '@tidbits/react-tidbits';
import UserAvatarFull from '@ui-components/user-avatar-full';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../common/types';
import AccordionList from '@ui-components/accordion-list';
import { getAccordionTitle } from '../../utils/utils';

const AccordionTitle = ({ title }: { title: string }) => {
  return (
    <Box py="8px">
      <Text color="labelPlaceholder" mb="3px">
        Workspace
      </Text>
      <Text textStyle="h5Emph" color="title">
        {title}
      </Text>
    </Box>
  );
};

const MyProfile = () => {
  const [activeAccordionName, setActiveAccordionName] = useState('');
  const user = useSelector((state: ReduxState) => state.user);

  let nameSpaces = user.permissions.filter((p) => p.includes('CAN_ACCESS_NAMESPACE'));
  let accordions = nameSpaces.map((ns) => getAccordionTitle(ns));

  const handleAccordionClick = (name: string) => {
    activeAccordionName === name ? setActiveAccordionName('') : setActiveAccordionName(name);
  };

  return (
    <Panel height={'100%'}>
      <>
        <UserAvatarFull fullName={user.name} email={user.email} />
        <Box mt="30px">
          <AccordionList
            activeAccordionName={activeAccordionName}
            handleAccordionClick={handleAccordionClick}
          >
            {accordions.map((acc) => {
              return (
                <AccordionList.Accordion
                  title={<AccordionTitle title={acc} key={acc} />}
                  name={acc}
                >
                  <Box p="16px">
                    <Text textStyle="bodySmallEmph" color="title">
                      Have access to {acc}
                    </Text>
                  </Box>
                </AccordionList.Accordion>
              );
            })}
          </AccordionList>
        </Box>
      </>
    </Panel>
  );
};

export default MyProfile;
