import React from 'react';
import { useSelector } from 'react-redux';
import { Panel, Text, Box, HR } from '@tidbits/react-tidbits';
import { PERMISSIONS } from '../../../../../common';
import { FeatureSetting } from '../../../../../common/types/UserPreferences';
import { FormInputs } from '../Input/FormInputs';
import { getUserPermissions } from '../../../../../store/user/userSelector';
import { getUserPreferencesBetaFeatures } from '../../../../../store/userPreferences/userPreferencesSelector';
import {
  SettingsType,
  UserPreferencesBetaFeature
} from '@helper-hooks/user-preferences-redux-action';
import styled from 'styled-components';
import { getTypeTitle } from '../../utils/utils';
import { BetaFeaturesConfig } from '../../config/BetaFeaturesConfig';

const Container = styled.div`
  overflow-y: scroll;
  height: 85%;
`;

export const BetaFeatures = () => {
  const isProd = window.location.host === 'wrkflow.app';
  const userPermissions = useSelector(getUserPermissions);
  const userPreferencesBetaFeatures: UserPreferencesBetaFeature = useSelector(
    getUserPreferencesBetaFeatures
  );
  const hasBetaFeaturesPermissions = userPermissions.includes(
    PERMISSIONS.canViewWorkflowBetaFeatures
  );

  const renderFormInputs = (workflowType: string, feature: FeatureSetting) => {
    const { propertyKey, prodEnabled } = feature;
    const workflowBetaFeatures =
      userPreferencesBetaFeatures[workflowType as keyof typeof userPreferencesBetaFeatures];
    const currentValue = workflowBetaFeatures[propertyKey as keyof typeof workflowBetaFeatures];
    if (process.env.REACT_APP_ENV === 'prod') {
      if (prodEnabled) {
        return (
          <FormInputs
            value={currentValue}
            settingsType={SettingsType.BETA_FEATURES}
            workflowType={workflowType}
            {...feature}
          />
        );
      } else {
        return null;
      }
    }

    return (
      <FormInputs
        value={currentValue}
        settingsType={SettingsType.BETA_FEATURES}
        workflowType={workflowType}
        {...feature}
      />
    );
  };

  const hasNamespacePermission = (permissions: string[]) => {
    for (let permission of permissions) {
      if (!userPermissions.includes(permission)) {
        return false;
      }
    }
    return true;
  };

  const hasProdEnabledFeatures = (features: FeatureSetting[]) => {
    return features.find((feature: FeatureSetting) => feature.prodEnabled);
  };

  if (!hasBetaFeaturesPermissions) {
    return null;
  }

  return (
    <Panel height={'100%'}>
      <Text sb="spacer15" textStyle="h3Emph">
        Beta Features
      </Text>
      <HR />
      <Container>
        {BetaFeaturesConfig.map(({ workflowType, features, namespacePermissions }) => {
          // No need to render anything if there's nothing enabled for prod
          if (isProd && !hasProdEnabledFeatures(features)) {
            return null;
          }
          if (hasNamespacePermission(namespacePermissions || []) && features.length > 0) {
            return (
              <Box pb={15} pt={15}>
                <Text textStyle="h5Medium" sb="spacer10">
                  {getTypeTitle(workflowType)}
                </Text>
                {features.map((feature: any) => {
                  return <>{renderFormInputs(workflowType, feature)}</>;
                })}
                <HR />
              </Box>
            );
          }
          return null;
        })}
      </Container>
    </Panel>
  );
};
