import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from '@ui-components/style-constants';
import { Panel, Text } from '@tidbits/react-tidbits';

const ServerErrorMessage = ({ header, message }) => (
  <Panel style={{ backgroundColor: `#${Colors.errorBg}` }}>
    <Text sb="spacer15" textStyle="h4Emph">
      {header}
    </Text>
    <Text sb="spacer20">{message}</Text>
  </Panel>
);

ServerErrorMessage.defaultProps = {
  header: ''
};

ServerErrorMessage.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string.isRequired
};

export default ServerErrorMessage;
