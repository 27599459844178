import React from 'react';
import styled from 'styled-components';
import { Panel, Text, Link } from '@tidbits/react-tidbits';

const contacts = [
  {
    title: 'Apps Support',
    email: 'Shakespeare_Apps_FC_Support@group.apple.com'
  },
  {
    title: 'Music Support',
    email: 'Shakespeare_Music_FC_Support@group.apple.com'
  },
  {
    title: 'Video Support',
    email: 'Shakespeare_Video_FC_Support@group.apple.com'
  },
  {
    title: 'Functional Bugs',
    email: 'Shakespeare_Escalations@group.apple.com'
  },
  {
    title: 'Account Management',
    email: 'external_fc_requests@apple.com'
  }
];

const ContactContainer = styled.div`
  padding-bottom: 15px;
`;

export const QueriesAndEscalations = () => {
  return (
    <Panel height={'100%'}>
      <Text sb="spacer15" textStyle="h3Emph">
        Queries and Escalations
      </Text>
      <Text sb="spacer20">VPMs can send queries to the appropriate email groups:</Text>
      {contacts.map(({ title, email }, index) => {
        return (
          <ContactContainer key={index}>
            <Text textStyle="bodyEmph" sb="spacer15">
              {title}
            </Text>
            <Link
              onClick={() => {
                window.location.href = `mailto:${email}`;
              }}
            >
              {email}
            </Link>
          </ContactContainer>
        );
      })}
    </Panel>
  );
};
