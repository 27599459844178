import { QualifiedUser } from '@providers/types-provider';
import {
  GetReassignListRequestAction,
  GetReassignListSuccessAction,
  TaskReassignActionTypes,
  ClearReassignListAction
} from './reassignTypes';

export const getReassignListRequest = (
  payload: GetReassignListRequestAction['payload']
): GetReassignListRequestAction => ({
  type: TaskReassignActionTypes.GET_REASSIGN_LIST_REQUEST,
  payload
});

export const getReassignListSuccess = (payload: QualifiedUser[]): GetReassignListSuccessAction => ({
  type: TaskReassignActionTypes.GET_REASSIGN_LIST_SUCCESS,
  payload
});

export const clearReassignList = (): ClearReassignListAction => ({
  type: TaskReassignActionTypes.CLEAR_REASSIGN_LIST
});
